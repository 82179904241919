<template>
  <div class="comtain">
    <navbar :title="(id > 0 ? '编辑' : '添加')  + '企业发票抬头'"></navbar>
    <div class="form-block">
      <van-form ref="form" :show-error-message="false" :show-error="false">
        <div class="form-con">
          <div class="cell">
            <span class="red">*</span>
            <van-field
              v-model="form.company_name"
              name="company_name"
              label="企业名"
              placeholder="请输入"
              :rules="[{ required: true, message: '请填写企业名' }]"
            />
          </div>
          <div class="cell">
            <span class="red">*</span>
            <van-field
              v-model="form.taxpayer_code"
              name="taxpayer_code"
              label="纳税人识别码"
              placeholder="请输入"
              :rules="[{ required: true, message: '请填写纳税人识别码' }]"
            />
          </div>
          <div class="cell">
            <van-field
              v-model="form.address"
              name="address"
              label="注册地址"
              placeholder="请输入"
            />
          </div>
          <div class="cell">
            <van-field
              v-model="form.telephone"
              name="telephone"
              label="注册电话"
              placeholder="请输入"
            />
          </div>
          <div class="cell">
            <van-field
              v-model="form.bank"
              name="bank"
              label="开户银行"
              placeholder="请输入"
            />
          </div>
          <div class="cell">
            <van-field
              v-model="form.bank_account"
              name="bank_account"
              label="银行账号"
              placeholder="请输入"
            />
          </div>
          <div class="cell">
            <div class="cell-c">
              <div>设为默认抬头</div>
              <van-switch v-model="form.is_default" />
            </div>
          </div>
        </div>
        <div class="btn">
          <van-button class="ch-btn" round block type="info-cus" @click="onSubmit"><span class="ch-btn-txt">保存</span></van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import {billDetail, addBill, editBill} from '@/api/invoice.js'

export default {
  name: 'invoiceDetail',
  components: {
    Navbar
  },
  data() {
    return {
      id: 0,
      form: {
        is_default: false
      }
    }
  },
  created() {
    this.id = this.$route.query.id || 0
    if (this.id) this.getDetail()
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then(_ => {
        let data = {...this.form}
        data.is_default = data.is_default ? 1 : 0
        if (this.id) {
          //编辑
          editBill(data)
            .then(res => {
              if(res.code == 0) {
                this.$toast('操作成功')
                this.$router.go(-1)
              } else {
                this.$toast(res.msg)
              }
            })
        } else {
          //新增
          addBill(data)
            .then(res => {
              if(res.code == 0) {
                this.$toast('操作成功')
                this.$router.go(-1)
              } else {
                this.$toast(res.msg)
              }
            })
        }
      }).catch(err => {
        if (err[0] != undefined) {
          this.$toast(err[0]['message']);
        }
      })
    },
    getDetail() {
      billDetail({id: this.id})
        .then(res => {
          if (res.code == 0) {
            this.form = res.data
            this.form.is_default = this.form.is_default ? true : false
          } else {
            this.$toast(res.msg)
            this.$router.go(-1)
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.comtain{
  background: #fff !important;
  height: 100vh;
}
.form-block{
  .form-con{
    padding-top: 10px;
    @include safe-area-inset-bottom('170px');
    .cell{
      position: relative;
      margin: 20px 20px 0;
      .red{
        position: absolute;
        left: 20px;
        top: 27px;
        color: #EB3C3C;
        font-size: 30px;
        z-index: 1;
      }
      .van-cell{
        padding: 26px 30px 26px 42px;
        border-radius: 10px;
        font-size: 30px;
      }
      :deep(.van-field__label){
        width: 200px;
        font-size: 30px;
        color: #4F4F4F;
        font-weight: bold;
      }
      .cell-c{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 26px 30px 26px 42px;
        background: #F4F9FC;
        border-radius: 10px;
        font-size: 30px;
        color: #4F4F4F;
        font-weight: bold;
      }
    }
  }
 .btn{
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 30px 30px 0;
  @include safe-area-inset-bottom('30px');
  background: #fff;
  box-sizing: border-box;

  left: 0;
  .ch-btn {
    .ch-btn-txt{
      font-size: 36px;
    }
  }
 }
}
</style>