import axios from '@/lib/axios'

//发票列表
export const billList = (data) => {
    return axios.request({
        url: 'api/billList',
        data: data,
        method: 'post'
    });
};

//发票详情
export const billDetail = (data) => {
    return axios.request({
        url: 'api/billDetail',
        data: data,
        method: 'post'
    });
};

//添加发票
export const addBill = (data) => {
    return axios.request({
        url: 'api/billCreate',
        data: data,
        method: 'post'
    });
};

//修改发票
export const editBill = (data) => {
    return axios.request({
        url: 'api/billUpdate',
        data: data,
        method: 'post'
    });
};

//删除发票
export const delBill = (data) => {
    return axios.request({
        url: 'api/billDelete',
        data: data,
        method: 'post'
    });
};